import React, {lazy, Suspense} from "react";
import { createRoot } from "react-dom/client";
import Loading from "./shared/Loading";

const OrderList = lazy(() => import('./orderModule/orderList'));
const PendingOrderDetails = lazy(() => import('./orderModule/orderDetails/pending'))
const LiveOrderDetails = lazy(() => import('./orderModule/orderDetails/live'))

export const renderReactComponent = (componentName, targetElementId, props) => {

  let targetElement;

  const wrapSuspense = (component) => {
    return (
      <Suspense fallback={<Loading/>}>
        {component}
      </Suspense>
    )
}

  window.addEventListener("DOMContentLoaded", function (e) {
    targetElement = createRoot(document.getElementById(targetElementId));

    if (!targetElement) {
      console.error(`Cannot find target element with ID "${targetElementId}"`);
      return;
    }

    switch (componentName) {
      case "GetAllOrders":
        targetElement.render(wrapSuspense(<OrderList {...props} />));
        break;
      case "OrderDetails":
        targetElement.render(wrapSuspense(<LiveOrderDetails {...props} />));
        break;
      case "OrderDetailsPending":
        targetElement.render(wrapSuspense(<PendingOrderDetails {...props} />));
        break;
      default:
        console.error(`Unknown component name "${componentName}"`);
    }
  });
};

function delayForDemo(promise) {
  return new Promise(resolve => {
    setTimeout(resolve, 2000);
  }).then(() => promise);
}

