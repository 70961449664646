import React, {lazy} from "react";
import "./App.scss";
const OrderList = lazy(() => import('./orderModule/orderList'));
const PendingOrderDetails = lazy(() => import('./orderModule/orderDetails/pending'))
const LiveOrderDetails = lazy(() => import('./orderModule/orderDetails/live'))

const props= {
  tenant: 'vetsny_db'
}
const App = () => {
  return (
    <div className="reset-elements">
      {/* <PendingOrderDetails {...props}/> */}
      {/* <LiveOrderDetails {...props}/> */}
       <OrderList {...props}/>
    </div>
  );
};

export default App;
