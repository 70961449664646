import React from "react";
const Loading = () => {
  return (
    <div className="container-fluid d-flex justify-content-center bg-white">
      <h5 className="text-hint p-4 align-content-center">
        <div className="spinner-grow spinner-grow-sm text-hint m-r-5" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </h5>
    </div>
  );
};

export default Loading;
