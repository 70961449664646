import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { initializeIcons } from "@fluentui/react/lib/Icons";
import { renderReactComponent } from "./library";


if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
  localStorage.setItem("authToken", "riZz752d_7GVCNpicaM6");
}

const root = document.getElementById("root") !== null && ReactDOM.createRoot(document.getElementById("root")).render(<App />);

window.renderReactComponent = (componentName, targetElementId, props) => {
  renderReactComponent(componentName, targetElementId, props)
};

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
initializeIcons();
